var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "pt-0 page-background mainArea",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("TopBar", { attrs: { title: "Feature Service" } }),
          _c(
            "div",
            {
              staticClass:
                "page-background d-flex flex-column align-center px-1 py-2",
            },
            [
              _c(
                "section",
                [
                  _c(
                    "div",
                    { staticClass: "caption" },
                    [
                      _c("v-icon", [_vm._v(_vm._s(_vm.mdiLayersPlus))]),
                      _vm._v(" Convert Feature Service "),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "my-1",
                      attrs: {
                        width: _vm.$vuetify.breakpoint.xsOnly ? "100%" : "auto",
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "d-flex align-center gap px-5" },
                        [
                          _c(
                            "section",
                            [
                              _c("div", { staticClass: "caption" }, [
                                _vm._v("Select Layer"),
                              ]),
                              _c("validation-provider", {
                                attrs: { name: "Layer", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            autocomplete: "off",
                                            label: "Layer",
                                            items: _vm.mapServiceChoices,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            "hide-details": "",
                                          },
                                          on: {
                                            change: _vm.getGisFieldsAndFeatures,
                                          },
                                          model: {
                                            value: _vm.mapServiceId,
                                            callback: function ($$v) {
                                              _vm.mapServiceId = $$v
                                            },
                                            expression: "mapServiceId",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "section",
                            [
                              _c("div", { staticClass: "caption" }, [
                                _vm._v("Select Fields"),
                              ]),
                              _c("validation-provider", {
                                attrs: { name: "Field" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            autocomplete: "off",
                                            label: "Field",
                                            items: _vm.fieldChoices,
                                            "item-text": "label",
                                            "item-value": "value",
                                            "error-messages": errors,
                                            success: valid,
                                            color: "primary",
                                            "hide-details": "",
                                            multiple: "",
                                          },
                                          model: {
                                            value: _vm.selectedFields,
                                            callback: function ($$v) {
                                              _vm.selectedFields = $$v
                                            },
                                            expression: "selectedFields",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.convertLayer },
                            },
                            [_vm._v("Convert")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.showConvertSuccessDialog,
                "max-width": "500px",
                persistent: "",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-text", { staticClass: "py-3" }, [
                    _vm._v(" Feature layer conversion is successful. "),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end px-5 py-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.showConvertSuccessDialog = false
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.showConvertFailureDialog,
                "max-width": "500px",
                persistent: "",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-text", { staticClass: "py-3" }, [
                    _c("p", [_vm._v("Feature layer conversion failed.")]),
                    _vm.errorMessage
                      ? _c("p", [_vm._v(_vm._s(_vm.errorMessage))])
                      : _vm._e(),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end px-5 py-3" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.showConvertFailureDialog = false
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }